import React from "react";
import Navbar from "./Navbar";
import { Link, useNavigate } from "react-router-dom";
import "./Home.css";
import Background1 from "../upload/logo_white.png";
import "./frontend.min.css";
import { AiOutlineShoppingCart } from "react-icons/ai";
import bac1 from "../img/back1.jpg";
import bac2 from "../img/bg-header.png";
// import '../js/plugins/craftcoffee-elementor/assets/js/flickity.pkgd.js'
// import '../css/wordpress.css';
// import '../css/reset.css'
import "./Home.css";
// import '../css/style.css';
import "./Navbar.css";
import "./custom-css.min.css";
import "./responsive.css";



import Animation from "../components/Animation ";
import { motion } from "framer-motion";
import { useState } from "react";
import AnmiText from "./AnmiText";
import Carousel from "./Carousel";
import Footer from "./Footer";
const Home = () => {
  const navigate = useNavigate();
  return (
    <div  
      className="fontss   background-img1"
      style={{ backgroundImage: `url(${bac1})` }}
    >
      <section>
        <Navbar />
      </section>
      <section className="textss">

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "410px",
          color: "white",
          textAlign: "center",
          marginTop: "80px",
        }}
      >
        <span className="star">
          <h1    data-aos="zoom-out-up" data-aos-duration="2500">
            *
          </h1>

          <h1
      
            className="font2"
            data-aos="zoom-out-down"
            data-aos-duration="1500"
         
          >
            The Power of
          </h1>
          <h1 
             className="font5"
            data-aos="zoom-out"
            data-aos-duration="1000"
            >
            coffee
          </h1>

          <h1 
             className="since"
             
                data-aos="zoom-out-down" data-aos-duration="2500"
                >
            *SINCE 2022*
          </h1>
        </span>
      </div>
            </section>

      <div className="bgg">
        <img 
        style={{width:'100%'}}
          src={require("../img/bg2.png")}
          alt=""

        />
        <div
          className="vertical"
          id="vertical1"
         
        ></div>
      </div>
      <section>
      <div className="container11">
             
                <img
                className="coffeepic-0"
                  data-aos="fade-up"
                  // data-aos-duration="500"
                  src={require("../img/coffee1.jpg")}
                  alt=""
                />
                <h1
                className="ourr"
                >
                  OUR PASSIONS
                </h1>
                <span
                className="para11"
                  style={{
                    inlineHeight: "1.1em",
                    textAlign: "center",
                    letterSpacing: "0.5px",
                    width:'82%',
                  
                  }}
                >
                  <h1 className="para" >
                    A CUP OF GOURMET COFFEE SHARED WITH A FRIEND IS <br />{" "}
                    HAPPINESS TASTED AND TIME WELL SPENT.
                  </h1>
                </span>
              </div>
      </section>

      <section className="tt">
        <div className="back22">
          <div className="back11">
            <div className="container2">
              {/* <div className="back1">
         <div className="back2"> */}

              {/* <AnmiText/> */}
              {/* home 2 */}
              <div className="left-hh"></div>
              <div className="left-h">
                <span className="left-1"></span>
                {/* <div> */}

                <img
                className="coffeepic-1"
                  data-aos="fade-up"
                  // data-aos-duration="500"
                  src={require("../img/coffee1.jpg")}
                 
                  alt=""
                />
                <h1
                className="our"
                
                >
                  OUR PASSIONS
                </h1>
                  {/* </div> */}
              </div>
              <div className="right-h">
                <div className="right-hh"></div>
                <span
                className="para1"
                  style={{
              
                    inlineHeight: "1.1em",
                    textAlign: "center",
                    letterSpacing: "0.5px",
                 
                
                  }}
                >
                  <h1 style={{ marginRight: "100px" }}>
                    A CUP OF GOURMET COFFEE SHARED WITH A FRIEND IS <br />{" "}
                    HAPPINESS TASTED AND TIME WELL SPENT.
                  </h1>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="container3"
        // style={{display:'flex',marginTop:'10px'}}
      >
        <div className="left-rr"
          style={{width:'50%'}}
        >

            <h1
              style={{
                fontWeight: "lighter",
                marginTop: "110px",
 
              }}
            >
              Exercitation photo booth stumptown tote bag Banksy, elit small
              batch  freegan sed. Craft beer elit seitan exercitation,
              photo booth et 8-bit  kale chips proident chillwave deep v
              laborum. Aliquip veniam delectus,  Marfa eiusmod Pinterest
              in do umami readymade swag. Selfies iPhone
            Kickstarter, drinking vinegar
            </h1>
      
        </div>
        <div className="right-r">
          <span>
            <img
            className="im"
              data-aos="fade-left"
              data-aos-duration="500"
              src={require("../img/fresh.jpg")}
              alt=""
              style={{ width: "69%", marginLeft: "100px" }}
            />
          </span>
        </div>
      </section>
      <section
        className="container33"
      >
        <div className="left-rr" style={{width: '50%',
                textAlign: 'center'}}>

            <h1
              style={{
                fontWeight: "lighter",
             
              }}
            >
              Exercitation photo booth stumptown tote bag Banksy, elit small
              batch  freegan sed. Craft beer elit seitan exercitation.
            </h1>
      
        </div>
  
            <img
            className="im"
              data-aos="fade-up"
              data-aos-duration="500"
              src={require("../img/fresh.jpg")}
              alt=""
              style={{}}
            />
     
      
      </section>
      <section className="container66"
      
      style={{display:'flex',
        flexWrap: 'wrap',
    }}>
      
          <img
            src={require("../img/bg1.png")}
            alt=""
            style={{ width: "100%" }}
          />
          <div
          className="craftt"
          style={{lineHeight: '2px',
            width: '100%'}}
          >

          <h1
                        className="font3"
                        data-aos-duration="1500"
                        data-aos="zoom-in-up"
                        
            style={{
              textAlign: "center",
              color: "white",
              letterSpacing: "8px",
              fontSize: "76px",
              color: '#C7A17A',
              
            }}
            >
             Craft
            
          </h1>
       
              <h2
              style={{ fontSize: '110px',
              letterSpacing: '10px',textAlign:'center',color:'white'}}
              data-aos="zoom-in"
              data-aos-duration="1000">
             coffee
              </h2>
                </div>

              <img  className="bg-img"
            src={require("../img/bg2.png")}
            alt=""
            style={{ width: "100%" }}

            />



   
      </section>

      <section className="container5">
        <div
          className="vertical"
          style={{ marginTop: "-524px", height: "137px" }}
        ></div>
        <span>
          <div className="left-1">
            <img
              data-aos="fade-right"
              // data-aos-duration="500"
              src={require("../img/fresh1.jpg")}
              alt=""
              style={{ width: "60%", marginLeft: "119px" }}
            />
          </div>
        </span>
        <span>
          <div className="right-1">
            <h1
              style={{
                textAlign: "center",
                fontSize: "40px",
                textAlign: "center",
                marginRight: "56px",
                marginTop: "-180px",
              }}
            >
              THE FIRST CUP IS FOR THE GUEST, THE <br /> SECOND FOR ENJOYMENT,
              THE THIRD FOR THE SWORD
            </h1>
          </div>
        </span>
      </section>
      <section className="container55"
     
      >      
            <img
              data-aos="fade-up"
              // data-aos-duration="500"
              src={require("../img/fresh1.jpg")}
              alt=""
              style={{ width: "80%"}}
            />
  
    
            <h1
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight:'lighter'
           
                
            
              }}
            >
              THE FIRST CUP IS FOR THE GUEST, THE <br /> SECOND FOR ENJOYMENT,
              THE THIRD FOR THE SWORD
            </h1>
 
      </section>
      <section className="cont">
        <div
          // className="heading1"
          // data-aos="zoom-out-up"
          style={{ textAlign: "center", fontSize: "63px" }}
        >
          <h1>DELIGHTFUL</h1>
          <h1 style={{ marginLeft: "202px",marginTop:'-108px' }}>EXPEREIENCE</h1>
        </div>
        <div className="container6">
          <div className="paragraph">
            <p>
              Exercitation photo booth stumptown tote bag Banksy, elit small
              batch freegan sed. Craft beer elit seitan exercitation, photo
              booth.
            </p>
          </div>
          <div className="im1">
            <img
              data-aos="fade-up"
              //  data-aos-duration="500"
              src={require("../img/fresh3.jpg")}
              alt=""
              style={{ width: "60%", marginLeft: "125px" }}
            />
          </div>
          <div className="im2">
            <img
              data-aos="fade-up"
              // data-aos-duration="500"
              src={require("../img/fresh2.jpg")}
              alt=""
              style={{ width: "60%", marginLeft: "130px", marginTop: "-560px" }}
            />
          </div>
        </div>
      </section>
      <section className="cont2">
       
          <h1   style={{ textAlign: "center",
            fontSize: 'xx-large',        
        }}>DELIGHTFUL
            <br />
             EXPEREIENCE
             </h1>

       
        
        
            <img
              data-aos="fade-up"
              src={require("../img/fresh3.jpg")}
              alt=""
              style={{ width: "60%",
              
        
            
            
            }}
            />
             <p 
            style={{width: '87%',            
            textAlign:'center',
            fontSize: '22px',
            fontWeight: 'lighter',
        }}>
              Exercitation photo booth stumptown tote bag Banksy, elit small
              batch freegan sed. Craft beer elit seitan exercitation, photo
              booth.
            </p>
       
            <img
              data-aos="fade-up"
           
              src={require("../img/fresh2.jpg")}
              alt=""
              style={{ width: "60%" }}
            />
          
       
      </section>

      <section className="container77"
      
      style={{display:'flex',
        flexWrap: 'wrap',
    }}>
      
          <img
            src={require("../img/bg1.png")}
            alt=""
            style={{ width: "100%" }}
          />
          <div
          style={{lineHeight: '2px',
            width: '100%'}}
          >

          <h1
                        className="font3"
                        data-aos-duration="1500"
                        data-aos="zoom-in-up"
                        
            style={{
              textAlign: "center",
              color: "white",
              letterSpacing: "8px",
              fontSize: "76px",
              color: '#C7A17A',
              
            }}
            >
              our
            
          </h1>
       
              <h2
              style={{ fontSize: '110px',
              letterSpacing: '10px',textAlign:'center',color:'white'}}
              data-aos="zoom-in"
              data-aos-duration="1000">
                Shop
              </h2>
                </div>

              <img  className="bg-img"
            src={require("../img/bg2.png")}
            alt=""
            style={{ width: "100%" }}

            />



   
      </section>

      <section style={{ marginTop: "50px" }}>
        <Footer />
      </section>
    </div>

    //  </div>
  );
};

export default Home;
